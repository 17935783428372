import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Keyboard, KeyboardAvoidingView } from "react-native";
import { createParam } from "solito";
import { useLink } from "solito/link";
import { useRouter } from "solito/router";
import { z } from "zod";

import { type AuthenticateMagicLinkResultErrorType } from "@medbillai/graphql-types";

import {
  FullscreenSpinner,
  HapticsService,
  LoadingButton,
  Paragraph,
  Paragraph2,
  RootError,
  SchemaForm,
  Subheadline2,
  SubmitButton,
  XStack,
  YStack,
  applyFormErrors,
  formFields,
  isWeb,
} from "@medbillai/ui";

import { verifyEmailUrl } from "@medbillai/utils/urls";

import {
  ScreenViewCenter,
  ScreenViewTopThird,
  gradientBackgroundCircleScreenPadding,
} from "../../components/ScreenView";
import { analyticsService } from "../../lib/analytics";
import { useAuth } from "../../lib/auth";
import { SingularEvents, singularService } from "../../lib/singular";
import { useNotifications } from "../../provider/notifications";

export type LoginParams = {
  forceLogout?: boolean;
  magicLinkError?: AuthenticateMagicLinkResultErrorType;
};

const { useParams } = createParam<LoginParams>();

const SignInSchema = z.object({
  email: formFields.text.email(),
});

const magicLinkErrorMessages: Record<
  AuthenticateMagicLinkResultErrorType,
  string
> = {
  INVALID_PKCE_CODE_CHALLENGE:
    "The login link you followed was requested from a different device. For your security, please request log in again from this device.",
  UNABLE_TO_AUTH_MAGIC_LINK:
    "The login link you followed has expired or has already been used. Please try logging in again.",
  UNKNOWN_ERROR: "Something went wrong, please try again",
};

const ScreenViewComponent = isWeb ? ScreenViewCenter : ScreenViewTopThird;

export const LoginScreen = () => {
  const { params } = useParams();
  const { push } = useRouter();
  const { showAlert } = useNotifications();
  const { isReady, isAuthenticated, sendMagicLink, logout, user } = useAuth();
  const homeLink = useLink({ href: "/" });
  const [forceEnable, setForceEnable] = useState(false);

  let errorMessage: string | undefined = undefined;
  if (params?.magicLinkError) {
    errorMessage =
      magicLinkErrorMessages[params?.magicLinkError] ??
      magicLinkErrorMessages.UNKNOWN_ERROR;
  }

  const loggedOut = useRef(false);

  useEffect(() => {
    if (params?.forceLogout && !loggedOut.current) {
      loggedOut.current = true;
      void logout();
    }
  }, [params?.forceLogout, logout]);

  const form = useForm<z.infer<typeof SignInSchema>>();

  useEffect(() => {
    if (user?.profile?.email) {
      // Should dirty does not work on web.. so we need to force the form to be
      // enabled.
      form.setValue("email", user.profile.email, { shouldDirty: true });
      if (isWeb) {
        setForceEnable(true);
      }
    }
  }, [form, user?.profile?.email]);

  async function doSendMagicLink({ email }: z.infer<typeof SignInSchema>) {
    try {
      const result = await sendMagicLink(email);
      if (applyFormErrors(form, result)) {
        return;
      }
      //Sent Email successfully
      Keyboard.dismiss();
      if (result.__typename === "SendMagicLinkResponse") {
        singularService.setUserId(result.user.id);
        if (result.created) {
          analyticsService.trackEvent("UserCreated", { email });
          singularService.event(SingularEvents.completeRegistration);
        }
      }
      analyticsService.trackEvent("SubmitLogin", {});
      push(verifyEmailUrl(email));
    } catch (error) {
      showAlert({
        type: "error",
        message: "Unable to send a login link, please try again.",
      });
    }
  }

  async function handleEnterSubmit() {
    const { handleSubmit } = form;
    await handleSubmit(doSendMagicLink)();
  }

  if (!isReady) {
    return <FullscreenSpinner />;
  }

  if (isAuthenticated && !params?.forceLogout) {
    return (
      <ScreenViewComponent centerHorizontal extraPadding disableScroll>
        <Subheadline2>Welcome Back</Subheadline2>
        <Paragraph theme="alt1">You are already logged in.</Paragraph>
        <LoadingButton mt="$4" {...homeLink} f={1} width="100%">
          Go To Home
        </LoadingButton>
      </ScreenViewComponent>
    );
  }

  return (
    <KeyboardAvoidingView
      behavior="padding"
      // No heading so dont need offset
      keyboardVerticalOffset={0}
      contentContainerStyle={{ flex: 1 }}
      style={{ flex: 1, width: "100%" }}
    >
      <FormProvider {...form}>
        <ScreenViewComponent
          backgroundRadialGradient={isWeb ? false : true}
          centerHorizontal
          stackProps={{
            paddingTop: isWeb ? 0 : gradientBackgroundCircleScreenPadding,
          }}
          extraPadding={isWeb ? false : true}
          disableScroll
          renderAfter={
            !isWeb ? (
              <XStack paddingVertical="$5">
                <SubmitButton
                  onPress={handleEnterSubmit}
                  customHaptic={HapticsService.impactHeavy}
                  w="100%"
                >
                  Continue
                </SubmitButton>
              </XStack>
            ) : null
          }
        >
          <YStack flex={1}>
            <SchemaForm
              form={form}
              schema={SignInSchema}
              props={{
                email: {
                  onSubmitEditing: handleEnterSubmit,
                  returnKeyType: "go",
                  enablesReturnKeyAutomatically: true,
                  // autoFocus: true,
                  placeholder: "example@email.com",
                },
              }}
              onSubmit={doSendMagicLink}
            >
              {fields => (
                <YStack
                  gap="$4"
                  justifyContent="center"
                  $platform-web={{
                    gap: "$1",
                  }}
                >
                  <YStack
                    alignItems="center"
                    gap="$1.5"
                    $platform-web={{
                      pt: "$2",
                      gap: "$3",
                      pb: "$3",
                    }}
                  >
                    <Paragraph theme="alt1">Welcome</Paragraph>
                    <Subheadline2 textAlign="center">
                      What&apos;s your email address?
                    </Subheadline2>
                  </YStack>
                  {errorMessage && (
                    <Paragraph2 color="$red10">{errorMessage}</Paragraph2>
                  )}
                  {Object.values(fields)}
                  <RootError />
                  {isWeb && (
                    <SubmitButton
                      onPress={handleEnterSubmit}
                      mt="$4"
                      forceEnable={forceEnable}
                    >
                      Continue
                    </SubmitButton>
                  )}
                </YStack>
              )}
            </SchemaForm>
          </YStack>
        </ScreenViewComponent>
      </FormProvider>
    </KeyboardAvoidingView>
  );
};
