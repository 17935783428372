import type React from "react";

import { YStack } from "@medbillai/ui";

import { BackgroundRadialGrantedGradient } from "../../components/BackgroundRadialGrantedGradient";
import { GrantedLogo } from "../../components/local-images";

export type AuthLayoutProps = {
  children?: React.ReactNode;
};

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <YStack f={1} ai="center">
      <BackgroundRadialGrantedGradient />
      <YStack zIndex={40} mt="$10" height="$10">
        <GrantedLogo height={100} width={100} />
      </YStack>
      <YStack
        f={1}
        justifyContent="flex-start"
        alignItems="center"
        pt="$10"
        px="$4.5"
        maxWidth={600}
        width="100%"
      >
        <YStack
          shadowColor="$shadowColor"
          shadowOffset={{ width: 0, height: 5 }}
          shadowOpacity={1}
          shadowRadius={6}
          br="$8"
          width="100%"
        >
          <YStack
            gap="$3"
            justifyContent="center"
            backgroundColor="$background"
            borderWidth={1}
            borderColor="$strokeSecondary"
            p="$9"
            $sm={{
              p: "$4",
            }}
            br="$8"
            alignItems="center"
            width="100%"
          >
            {children}
          </YStack>
        </YStack>
      </YStack>
    </YStack>
  );
};
