import { useFormState } from "react-hook-form";

import { LoadingButton, type LoadingButtonProps } from "./LoadingButton";

/**
 * This hook will return the isSubmitting and isDirty values from the form
 * if the form is not found it will return isSubmitting: false and isDirty: true
 * which is equal to not loading and not disables
 */
const useFormStateValues = () => {
  try {
    // This component is sometimes used outside of a formProvider, so we need to
    // handle the case where useFormState is not available with try catch
    // It's safe to disable the rule here because we will render the hook only
    // when its available
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isSubmitting, isDirty } = useFormState();
    return { isSubmitting, isDirty };
  } catch {
    return { isSubmitting: false, isDirty: true };
  }
};

export type SubmitButtonProps = LoadingButtonProps & {
  /**
   * If true, will override default "disabled" logic
   * and always enable the button (unless loading).
   */
  forceEnable?: boolean;
};

/**
 * This button should only be used when inside a formProvider, it will
 * automatically detect if the form is dirty and if it is submitting otherwise
 * use loading button directly
 */
export const SubmitButton = (props: SubmitButtonProps) => {
  const { isSubmitting, isDirty } = useFormStateValues();
  const { forceEnable, disabled, ...restProps } = props;

  const defaultDisabled = !isDirty || isSubmitting || disabled;
  // if forceEnable is true, override so it's never disabled
  const finalDisabled = forceEnable ? false : defaultDisabled;
  return (
    <LoadingButton
      loading={isSubmitting}
      disabled={finalDisabled}
      {...restProps}
    />
  );
};
