import { useEffect, useRef, useState } from "react";
import { createParam } from "solito";
import { useLink } from "solito/link";

import {
  AppStoreIcon,
  ExternalLink,
  Paragraph,
  Paragraph2,
  Subheadline2,
  XStack,
  YStack,
} from "@medbillai/ui";

import { ScreenView } from "../components/ScreenView";
import { useAuth } from "../lib/auth";
import { appStoreUrl } from "../lib/constants";

export type LoginParams = {
  forceLogout?: boolean;
};
const { useParams } = createParam<LoginParams>();

export const WebappGate = () => {
  const { params } = useParams();
  const { logout } = useAuth();

  const loggedOut = useRef(false);

  useEffect(() => {
    if (params?.forceLogout && !loggedOut.current) {
      loggedOut.current = true;
      void logout();
    }
  }, [params?.forceLogout, logout]);

  const [browserInfo, setBrowserInfo] = useState("");

  useEffect(() => {
    setBrowserInfo(navigator.userAgent);
  }, []);

  const mailSubject = encodeURIComponent(
    "Issue while opening the link on my iOS device",
  );
  const mailBody = encodeURIComponent(
    `
   Hi,

   I have an issue opening a link I received on iOS:

   Email client:
   Browser: ${browserInfo}
   `,
  );

  return (
    <ScreenView
      centerHorizontal
      disableScroll
      stackProps={{ alignItems: "center", gap: "$4" }}
    >
      <YStack ai="center">
        <Subheadline2 mb="$4" ta="center">
          Open link on iOS
        </Subheadline2>
      </YStack>
      <Paragraph ta="center" theme="alt1">
        We only support iOS at this time.
      </Paragraph>
      <XStack
        {...useLink({
          href: appStoreUrl,
        })}
      >
        <AppStoreIcon />
      </XStack>
      <XStack gap="$2" ai="center" jc="center" theme="alt1">
        <Paragraph2>Issue with the link?</Paragraph2>
        <Paragraph2>
          <ExternalLink
            title="Report bug"
            href={`mailto:support@grantedhealth.com?subject=${mailSubject}&body=${mailBody}`}
          />
        </Paragraph2>
      </XStack>
    </ScreenView>
  );
};
